import { useContext } from 'react';
import { HeaderContext } from '@contexts/headers';

export const useHeaders = () => {
  const context = useContext(HeaderContext);

  if (!context) throw new Error('Store Context must be withing Store provider');

  return context;
};
