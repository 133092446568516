import { getCookie as getCookieFromServer } from 'cookies-next/server';
import {
  deleteCookie,
  getCookie,
  getCookies,
  hasCookie,
  setCookie
} from 'cookies-next/client';
import { ReadonlyRequestCookies } from 'next/dist/server/web/spec-extension/adapters/request-cookies';

export const APP_STORAGE_KEYS = {
  postcode: 'postcode',
  storeViewCode: 'store_view_code',
  storeViewCurrency: 'store_view_currency',
  storeViewSecureBaseMediaUrl: 'store_view_secure_base_media_url',
  signinToken: 'signin_token',
  cartId: 'cart_id',
  signatureInterval: 'signatureInterval',
  recurrenceConfig: 'recurrenceConfig',
  storeVendorFallbackCode: 'store_vendor_fallback_code',
  adminUser: 'admin_user',
  utmCode: 'voxusmediamanager_campaign'
} as const;

/**
 * Persistence layer with expiration based on cookies for client and server side
 */

export class Persistence {
  static getRawItem(name: string) {
    try {
      return getCookie(name);
    } catch (error) {
      logDevelopmentError('getRawItem', error, name);
      return undefined;
    }
  }

  static getItem(name: string) {
    try {
      const cookie = getCookie(name);
      if (!cookie) {
        return undefined;
      }
      return JSON.parse(cookie);
    } catch (error) {
      logDevelopmentError('getItem', error, name);
      return undefined;
    }
  }

  /** Use this method only in server side.
   ** If you need to use this method make sure you import the {@link Persistence} class from "src/utils/persistence" instead of "@utils" because it will throw an error
   * because Next.js will import also the rest of them exported files from the index.ts file and some of the are not compatible with the server side
   * @param name - item key
   * @param cookiesCtx - cookies from server it comes from next/headers. You must pass the function {@link cookies} without calling it.
   */
  static async getItemFromServer(
    name: string,
    cookiesCtx: () => Promise<ReadonlyRequestCookies>
  ) {
    try {
      const cookie = await getCookieFromServer(name, { cookies: cookiesCtx });
      if (!cookie) {
        return undefined;
      }
      return JSON.parse(cookie);
    } catch (error) {
      logDevelopmentError('getItemFromServer', error, name);
      return undefined;
    }
  }

  static setItem(name: string, value: any, ttl?: number) {
    try {
      const timeStored = new Date().getTime();
      const cookie = setCookie(name, JSON.stringify(value), {
        expires: ttl ? new Date(timeStored + ttl) : undefined,
        path: '/'
      });
      return cookie;
    } catch (error) {
      logDevelopmentError('setItem', error, name);
      return undefined;
    }
  }

  static removeItem(name: string) {
    try {
      deleteCookie(name);
    } catch (error) {
      logDevelopmentError('removeItem', error, name);
    }
  }

  /** This method is not recommended to be used because it will remove all cookies from the domain including third-party cookies like Google Analytics
   * @deprecated
   */
  static clear() {
    try {
      const cookies = getCookies();
      for (const cookie in cookies) {
        deleteCookie(cookie);
      }
    } catch (error) {
      logDevelopmentError('clear', error);
    }
  }

  /**
   * Clear all user items mapped on the {@linkplain APP_STORAGE_KEYS} constant; This excludes all the `third-party` cookies
   */
  static clearUserItems() {
    Object.values(APP_STORAGE_KEYS).forEach(cookie => {
      try {
        if (!hasCookie(cookie)) return;
        deleteCookie(cookie);
      } catch (error) {
        logDevelopmentError('clearUserItems', error);
      }
    });
  }
}

const logDevelopmentError = (
  name: string,
  error: any,
  elementName?: string
) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(
      `Persistence.${name}`,
      elementName ? `: ${elementName}` : '',
      error
    );
  }
};
