import { useApolloClient } from '@apollo/client';
import { APP_STORAGE_KEYS, extractNumbers, Persistence } from '@utils';
import { first, isEmpty, last, orderBy } from 'lodash';
import { useCallback } from 'react';
import { clearCartDataFromCache } from './clearCacheData';
import { useStore } from './useStore';
import { useHeaders } from './useHeaders';

export const useStoreSwitcher = () => {
  const { handleSetStore } = useHeaders();

  const { currentStore, availableStores } = useStore();

  const apolloClient = useApolloClient();

  const handleSwitchToStore = useCallback(
    async (store, fallbackStore, useFallback = false, callback) => {
      if (store?.code === currentStore?.code && !useFallback) return;

      if (useFallback && !isEmpty(fallbackStore)) {
        handleSetStore(fallbackStore?.code);
        Persistence.setItem(
          APP_STORAGE_KEYS.storeViewCurrency,
          fallbackStore.currency
        );
        Persistence.setItem(
          APP_STORAGE_KEYS.storeViewSecureBaseMediaUrl,
          fallbackStore.secure_base_media_url
        );

        Persistence.removeItem(APP_STORAGE_KEYS.storeVendorFallbackCode);

        await clearCartDataFromCache(apolloClient);

        if (callback) callback();
      } else {
        handleSetStore(store?.code);
        Persistence.setItem(APP_STORAGE_KEYS.storeViewCurrency, store.currency);
        Persistence.setItem(
          APP_STORAGE_KEYS.storeViewSecureBaseMediaUrl,
          store.secure_base_media_url
        );

        if (fallbackStore) {
          Persistence.setItem(
            APP_STORAGE_KEYS.storeVendorFallbackCode,
            fallbackStore?.code
          );
        } else {
          Persistence.removeItem(APP_STORAGE_KEYS.storeVendorFallbackCode);
        }

        await clearCartDataFromCache(apolloClient);

        if (callback) callback();
      }
    },
    [apolloClient, currentStore?.code, handleSetStore]
  );

  const getStoresByPostcode = useCallback(
    postcode => {
      if (availableStores) {
        const stores = Array.from(availableStores).map(entry => last(entry));
        return stores.filter(store => {
          const ranges = store.vendor_postcode_ranges.filter(
            range => range[0] <= postcode && range[1] >= postcode
          );
          return ranges.length > 0;
        });
      }
      return [];
    },
    [availableStores]
  );

  const handleSwitchStoreByPostcode = useCallback(
    async (postcode, useFallback = false, callback?) => {
      const storesFound = getStoresByPostcode(extractNumbers(postcode));
      const store = first(orderBy(storesFound, ['vendor_priority'], ['asc']));
      let fallbackStore = null;

      if (store) {
        if (store.vendor_is_in_transition && storesFound.length > 1) {
          fallbackStore = first(
            orderBy(
              storesFound.filter(
                entry =>
                  entry?.code !== store?.code && !entry.vendor_is_in_transition
              ),
              ['vendor_priority'],
              ['asc']
            )
          );
        }
        handleSwitchToStore(store, fallbackStore, useFallback, callback);
        return true;
      } else {
        return 'Stores not available.';
      }
    },
    [getStoresByPostcode, handleSwitchToStore]
  );

  return {
    handleSwitchToStore,
    handleSwitchStoreByPostcode
  };
};
