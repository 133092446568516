import { APP_STORAGE_KEYS, Persistence } from '@utils';
import {
  ReactNode,
  createContext,
  memo,
  useCallback,
  useEffect,
  useState
} from 'react';

export interface iStore {
  storeCode: string;
  utmCode: string;
  handleSetStore(payload: string): void;
  handleSetUtmCode(payload: string): void;
}

export const HeaderContext = createContext({} as iStore);

export const HeaderProvider = memo(({ children }: { children: ReactNode }) => {
  const storeCodeFound = Persistence.getItem(APP_STORAGE_KEYS.storeViewCode);
  const utmCodeFound = Persistence.getItem(APP_STORAGE_KEYS.utmCode);
  const [storeCode, setStoreCode] = useState(storeCodeFound || 'default');
  const [utmCode, setUtmCode] = useState(utmCodeFound || '');

  const handleSetStore = useCallback(
    (payload: string) => setStoreCode(payload),
    []
  );
  const handleSetUtmCode = useCallback(
    async (payload: string) => {
      setUtmCode(payload);
      Persistence.setItem(APP_STORAGE_KEYS.utmCode, utmCode);
    },
    [utmCode]
  );

  useEffect(() => {
    Persistence.setItem(APP_STORAGE_KEYS.storeViewCode, storeCode);
  }, [storeCode]);

  return (
    <HeaderContext.Provider
      value={{ storeCode, utmCode, handleSetStore, handleSetUtmCode }}
    >
      {children}
    </HeaderContext.Provider>
  );
});

HeaderProvider.displayName = 'HeaderProvider';
