import { ReactNode, Suspense, useEffect } from 'react';
import LoadingIndicator from '../LoadingIndicator';
import { useHeaders } from '@hooks';

interface AppWrapperProps {
  children: ReactNode;
}

export const AppWrapper = ({ children }: AppWrapperProps) => {
  const { utmCode, handleSetUtmCode } = useHeaders();

  useEffect(() => {
    const getUtmCampaign = () => {
      if (typeof window === 'undefined') return null;

      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const utmParam = params.get('utm_campaign');
      return utmParam ? decodeURIComponent(utmParam.split('&')[0]) : null;
    };

    const urlContent = getUtmCampaign();
    if (urlContent && urlContent !== utmCode) {
      handleSetUtmCode(urlContent);
      window.location.reload();
    }
  }, [handleSetUtmCode, utmCode]);

  return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>;
};
